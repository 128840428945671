.project-container {
  display: flex;
  flex-direction: row;
  background-color: #f0f0f0;
  color: #494949;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  align-items: center;
  font-family: "Montserrat", sans-serif;
}

.project-image {
  width: 100px;
  height: 100px;
  -webkit-filter: drop-shadow(1px 1px 0 #494949)
    drop-shadow(-1px -1px 0 #494949);
  filter: drop-shadow(1px 1px 0 #494949) drop-shadow(-1px -1px 0 #494949);
  object-fit: contain;
}

.project-info {
  padding: 20px;
}

.project-title {
  margin: 0 0 10px 0;
}

.project-description,
.project-date {
  margin-bottom: 10px;
}

.project-date {
  font-style: italic;
}

.project-technologies {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.technology-icon {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.project-links {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.project-link {
  background-color: #494949;
  color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.project-link:hover {
  background-color: #6a6a6a;
}
