.contact-container {
  font-family: "Montserrat", sans-serif;
  color: #494949;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-row {
  display: flex;
  gap: 10px;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #494949;
  border-radius: 5px;
  box-sizing: border-box;
}
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #494949;
  border-radius: 5px;
  box-sizing: border-box;
  min-height: 150px;
  resize: none;
  overflow-y: auto;
}

.contact-form button {
  background-color: #494949;
  color: #f0f0f0;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #6a6a6a;
}

.contact-socials a.social-link {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #494949;
  text-decoration: none;
  margin-bottom: 10px;
}

.contact-socials a.social-link:hover {
  text-decoration: underline;
  color: #6a6a6a;
}
