* {
  font-family: "Montserrat", sans-serif;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #494949;
  color: #f0f0f0;
}

.footer-content,
.copy-right {
  margin-bottom: 10px;
}

.social-links a,
.footer-nav a {
  color: #f0f0f0;
  text-decoration: none;
  margin: 0 15px;
}

.social-links i {
  font-size: x-large;
}

.footer-nav {
  margin: 20px 0;
}

.copy-right {
  font-size: small;
}

.location {
  font-size: 14px;
}
