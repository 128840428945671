.me-page {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 50px;
  gap: 50px;
  margin: auto;
}

.text-content {
  flex: 1;
  max-width: 600px;
}

.text-content h1 {
  font-size: 4.5rem;
  color: #494949;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: xx-large;
}

.text-content p {
  font-size: medium;
  color: #494949;
  line-height: 1.6;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
}

.resume-button {
  background-color: #494949;
  color: #f0f0f0;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  margin: 0 auto;
  transition: background-color 0.3s ease;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resume-button i {
  margin-left: 8px;
  font-size: 1rem;
}

.resume-button:hover {
  background-color: #333;
}

.image-content {
  flex: 1;
  text-align: right;
}

.profile-picture {
  max-width: 60%;
  height: auto;
  padding-bottom: -20 vh;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .me-page {
    flex-direction: column;
    padding: 20px;
    padding-top: 75px;
  }

  .text-content,
  .image-content {
    max-width: 100%;
  }

  .image-content {
    text-align: center;
    margin-bottom: 20px;
  }
}
