@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

* {
  box-sizing: border-box;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #494949;
  color: #f0f0f0;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  padding: 10px 20px;
  margin: 0;
  position: relative;
  height: 75px;
  z-index: 10;
}

.logo-and-name-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 65px;
  width: auto;
}

.name {
  margin-left: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: larger;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 25px;
  font-size: large;
}

.nav-links li {
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 15px;
}

.nav-link {
  display: block;
  text-decoration: none;
  color: #f0f0f0;
  padding: 10px;
  width: 100%;
}

.nav-links li:hover {
  background-color: rgba(240, 240, 240, 0.2);
}

.hamburger {
  display: none;
  background: none;
  border: none;
  color: #f0f0f0;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 10px 5px;
  margin-right: 0;
}

.language-toggle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  color: #f0f0f0;
  font-size: 30px;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-left: 20px;
}
.mobile-language-toggle {
  padding: 10px;
  text-align: center;
  width: 100%;
  font-size: 24px;
}

.language-toggle:active {
  transform: scale(1.5);
}

@media (max-width: 768px) {
  .navbar {
    justify-content: space-between;
  }

  .logo-and-name-container,
  .hamburger {
    flex-basis: auto;
    flex-grow: 0;
  }

  .hamburger {
    display: block;
  }

  .nav-links {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: none;
    background-color: #494949;
    padding: 0;
    margin-top: -1px;
  }

  .nav-links.open {
    display: flex;
  }
  .language-toggle {
    display: none;
  }
}
