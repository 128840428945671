html {
  background-color: #494949;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  background-color: #f0f0f0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}
