.experience-container {
  display: flex;
  flex-direction: row;
  background-color: #f0f0f0;
  color: #494949;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  align-items: center;
  font-family: "Montserrat", sans-serif;
}

.company-icon {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.experience-info {
  padding: 20px;
}

.experience-role,
.experience-company {
  margin: 0 0 10px 0;
}

.experience-location,
.experience-period {
  margin-bottom: 10px;
  font-style: italic;
}

.experience-detail {
  margin-bottom: 5px;
}
